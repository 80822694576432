let testicon = L.divIcon({
  // html: `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="arcs"> <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"> </path> </svg>`,
  html: `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 20 20"><circle cx="10" cy="10" r="10" stroke="currentColor" fill="currentColor" /></path></svg>`,
  iconUrl: `/images`,
  className: "homeIcon",
  iconSize: [7, 7],
  iconAnchor: [10, 10],
});

export { testicon };
